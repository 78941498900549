import React, { useCallback, useEffect, useState } from 'react';
import {
    ReactFlow,
    addEdge,
    useNodesState,
    useEdgesState,
    MiniMap,
    Background,
    Controls,
    Panel,
    Position,
    applyEdgeChanges,
    applyNodeChanges,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import Header from '../Header';
import Loader from '../../common/Loader';
import TextUpdaterNode from './TextUpdaterNode';
import { useNavigate, useParams } from 'react-router-dom';
import './drawflow.css'
import * as Icon from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { getBroadcastTemplateList, getChatbotDetailByIdApi, saveChatbotApi } from '../../../api/templateApi';
import { useAuthContext } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import { formateDateTime } from '../../../utils/dateTimeManage';
import Pagination from '../../common/Pagination';
import { v4 as uuidv4 } from 'uuid';



const ChatBotFlow = () => {
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chatbotName, setChatbotName] = useState('');
    const [chatbotNameErr, setChatbotNameErr] = useState(false);
    const navigate = useNavigate();
    const [colorMode, setColorMode] = useState('light');
    const [show, setShow] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [finalSearchText, setFinalSearchText] = useState('')
    const [timerId, setTimerId] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const { logoutUser } = useAuthContext();
    // extra data for edit functionality
    const { chatbotId } = useParams();

    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);


    const handleCloseEditNodePopup = () => setShow(false);
    const handleOpenModel = () => {
        setShow(true)
    };

    const removeNode = (id) => {
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
    };

    const updateNodeLabel = (id, newLabel) => {
        setNodes((nds) =>
            nds.map((node) => (node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node))
        );
    };

   


    const toggleSidebar = () => {
        setSidebarHideShow((prevState) => !prevState);
    };

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const onConnect = useCallback((params) => setEdges((eds) => {
        
        // Check if the source handle is already connected
        const isAlreadyConnected = eds.some(
            (edge) =>
                edge.source === params.source && edge.sourceHandle === params.sourceHandle
        );

        if (isAlreadyConnected) {
            toast.error("This output handle is already connected!");
            return eds; // Do not add a new edge
        }
        let edgeId = uuidv4()
        params['id'] = `edge-${edgeId}`

        return addEdge(params, eds)
    })
        , [setEdges]);

    const handleColorModeChange = (event) => {
        setColorMode(event.target.value);
    };

    const handleInit = (instance) => {
        instance.setViewport({ x: 0, y: 0, zoom: 1.2 }); // Programmatically set viewport
    };

    const addNewNode = (templateInfo) => {
        let id = uuidv4()

        const newNode = {
            id: `node-${id}`,
            type: "output",
            position: { x: Math.random() * 400, y: Math.random() * 400 },
            data: {
                id: `node-${id}`,
                type: nodes.length == 0 ? "startNode" : "middleNode",
                onRemove: removeNode,
                onUpdate: updateNodeLabel,
                templateInfo: templateInfo
            },
        }

        setNodes((nds) => [...nds, newNode]);
        handleCloseEditNodePopup();
    };

    const saveChatbot = () => {

        if (chatbotName === "") {
            setChatbotNameErr(true)
            return
        } else {
            setChatbotNameErr(false)
        }
        let finalNodeData = [];
        nodes.forEach((nodeInfo, index) => {
            let tempNode = {};

            tempNode['id'] = nodeInfo['id'];
            tempNode['type'] = nodeInfo['type'];
            tempNode['position'] = nodeInfo['position'];
            tempNode['measured'] = nodeInfo['measured'];

            tempNode['nodePosition'] = nodeInfo['data']["type"];
            tempNode['isAlreadyExistingTemp'] = true;
            tempNode['templateId'] = nodeInfo['data']["templateInfo"]["_id"];
            finalNodeData.push(tempNode)
        })

        if (finalNodeData.length > 0) {
            let postData = {
                'chatbotName': chatbotName,
                'finalNodeData': finalNodeData,
                'connections': edges,
            }
            if(chatbotId){
                postData['chatbotId'] = chatbotId;
            }
            saveChatbotToServer(postData)
        } else {
            alert('Atleast one node is required.')
        }
    }

    const saveChatbotToServer = async (postData) => {
        setLoading(true);
        try {
            const responceData = await saveChatbotApi(postData);
            if (responceData?.data) {
                toast.success(`Chatbot ${chatbotId?'updated':'created'} successful!`);
                navigate('/chatbot')
            } else {
                toast.success('Save Chatbot failed!');
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Save Chatbot failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleTemplateList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: itemsPerPage,
                skip: (currentPage - 1) * itemsPerPage,
                search: finalSearchText,
                type: 'cloud'
            }
            const responceData = await getBroadcastTemplateList(postData);

            if (responceData?.data) {
                setTotalItems(responceData.count)
                setTemplateList(responceData.data);
            } else {
                setTotalItems(0)
                setTemplateList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    // Event handler for input change
    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearch(text);

        // Debouncing logic
        if (timerId) {
            clearTimeout(timerId);
        }
        const newTimerId = setTimeout(() => {
            setFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTimerId(newTimerId);
    };

    const getChatbotDetailById = async () => {
        setLoading(true);
        try {
            let postData = {
                id: chatbotId
            }
            const responceData = await getChatbotDetailByIdApi(postData);
            console.log('responceData', responceData);

            if (responceData?.data) {
                let chatbotInfo = responceData?.data[0]
                handleEditChatbotTemplate(chatbotInfo)
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Save Chatbot failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleEditChatbotTemplate = (chatbotInfo) => {
        setChatbotName(chatbotInfo.name)
        let newNodes = []
        chatbotInfo.levels.map((template,index)=>{
            newNodes.push({
                id: `${template.nodeId}`,
                type: template.type,
                position: template.position,
                measured: template.measured,
                data: {
                    id: `${template.nodeId}`,
                    type: template.nodePosition, // start node/other
                    onRemove: removeNode,
                    onUpdate: updateNodeLabel,
                    templateInfo: template.templateData[0]
                },
            })
        })
        setNodes(newNodes);
        setEdges(chatbotInfo.connections?chatbotInfo.connections:[])
    }


    useEffect(() => {
        handleTemplateList();
    }, [currentPage, finalSearchText])

    useEffect(() => {
        if (chatbotId) {
            getChatbotDetailById()
        }
    }, [chatbotId])


    return (
        <>
            <Header toggleSidebar={toggleSidebar} sidebarHideShow={sidebarHideShow} />
            {loading && <Loader />}

            <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`}>
                <div className="sidebar-wrap" style={{ marginTop: '50px', marginLeft: '1px' }}>
                    <div className="sidebar">
                        <div className="container" style={{ padding: '0 10px' }}>
                            <div className="row mb-4">
                                <div className="col-12 px-0">

                                    <button
                                        onClick={() => {
                                            navigate('/chatbot')
                                        }}
                                        className="btn btn-theme btn-md"><Icon.ArrowLeft size={20} /> Back</button>
                                    <div>
                                        <input type='text'
                                            placeholder='Chatbot Name'
                                            style={chatbotNameErr ? { 'borderBlockColor': 'red' } : {}}
                                            value={chatbotName}
                                            className="form-control border-start-0 mb-2 mt-2"
                                            onChange={(e) => {
                                                setChatbotName(e.target.value);
                                                if (e.target.value === "") {
                                                    setChatbotNameErr(true)
                                                } else {
                                                    setChatbotNameErr(false)
                                                }
                                            }}
                                        />
                                    </div>

                                    <ul className="nav nav-pills" style={{ borderRadius: '5px' }}>
                                        <li
                                            className="nav-item"
                                            style={{ borderRadius: '5px', paddingLeft: '10px', backgroundColor: '#28cb28' }}
                                            onClick={() => saveChatbot()}
                                        >
                                            <a className="nav-link">

                                                <div className="col">
                                                    <Icon.Save size={20} /> Save</div>
                                            </a>
                                        </li>
                                        <li
                                            className="nav-item"
                                            style={{ borderRadius: '5px', paddingLeft: '10px', backgroundColor: '#c782bcde' }}
                                            onClick={() => handleOpenModel()}
                                        >
                                            <a className="nav-link">

                                                <div className="col">
                                                    <Icon.RecordCircle size={20} /> Template</div>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <main className="main mainheight" style={{ paddingTop: 'calc(env(safe-area-inset-top) + 100px)' }}>
                    <div className="container" style={{ padding: '0' }}>
                        <div style={{ width: '85vw', height: '90vh' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                colorMode={colorMode}
                                fitView
                                nodeTypes={{ input: TextUpdaterNode, output: TextUpdaterNode }}
                                onInit={handleInit}

                            >
                                <MiniMap />
                                <Background />
                                <Controls />

                                <Panel position="top-right">
                                    <select onChange={handleColorModeChange} data-testid="colormode-select">
                                        <option value="dark">dark</option>
                                        <option value="light">light</option>
                                        <option value="system">system</option>
                                    </select>
                                </Panel>
                            </ReactFlow>
                        </div>
                    </div>
                </main>
            </div>

            {/* manage edit model */}
            <Modal show={show} onHide={handleCloseEditNodePopup} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Select Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading ? (
                        <div className="card-body p-0">
                            <div className="col-auto sm-auto">
                                <input type="text" className="form-control" placeholder="Search..." value={search} onChange={handleSearchChange} />
                            </div>
                            <table className="table footable" data-show-toggle="true">
                                <thead>
                                    <tr className="text-muted">
                                        <th>Sr No.</th>
                                        <th>Template Name</th>
                                        <th>Category</th>
                                        <th>Status</th>
                                        <th>Created Date</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {(templateList.length > 0) ? (
                                        templateList.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(index + 1) + (itemsPerPage * (currentPage - 1))}</td>
                                                <td>{item.name}</td>
                                                <td>{item.category}</td>
                                                <td> <span style={{ color: item.status == "PENDING" || item.status == "REVIEW" ? 'orange' : item.status == "APPROVED" ? 'green' : 'red' }}>{item.status}</span></td>
                                                <td>{formateDateTime(item.createdAt)}</td>
                                                <td>
                                                    <Icon.CursorFill size={18} color="red" className='cursor-pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (item.status == "APPROVED") {
                                                            addNewNode(item);
                                                        } else {
                                                            toast.error("Template is not approved by meta.")
                                                        }

                                                    }}

                                                    />


                                                </td>
                                            </tr>

                                        ))
                                    ) : (
                                        <tr key="0" >
                                            <th colSpan="20" style={{ textAlign: 'center' }}>No data available..</th>
                                        </tr>
                                    )}



                                </tbody>
                            </table>
                            {totalItems > 0 &&
                                <Pagination
                                    currentPage={currentPage}
                                    totalItems={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            }
                        </div>
                    ) : (
                        <Loader />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditNodePopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ChatBotFlow;
