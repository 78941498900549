import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

import Header from '../../Website/Header';
import Footer from '../Footer';

import { useNavigate } from 'react-router-dom';
import { deleteGroup, getGroupList } from '../../../api/groupApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Swal from 'sweetalert2';
import Loader from '../../common/Loader';
import Pagination from '../../common/Pagination';
import { formateDateTime } from '../../../utils/dateTimeManage';
import Sidebar from '../Sidebar';
// import socket from '../../../utils/socket';

function GroupList() {
    const navigate = useNavigate();
    const [groupList, setGroupList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { logoutUser } = useAuthContext();
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [finalSearchText, setFinalSearchText] = useState('')
    const [timerId, setTimerId] = useState(null);


    const handleGroupList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: itemsPerPage,
                skip: (currentPage - 1) * itemsPerPage,
                name: finalSearchText
            }
            console.log('postData', postData);
            const responceData = await getGroupList(postData);
            if (responceData?.result?.data) {
                setTotalItems(responceData.result.count)
                setGroupList(responceData.result.data);
            } else {
                setTotalItems(0)
                setGroupList([]);
            }

        } catch (error) {
            console.log('error', error);
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Group list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleGroupDelete = async (item) => {
        Swal.fire({
            title: "Are you sure?",
            html: `Want to delete <b>${item.name}</b> group.`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",

        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    await deleteGroup(item._id);
                    handleGroupList();
                    Swal.fire({
                        title: "Deleted!",
                        html: `<b>${item.name}</b> group deleted successfuly!.`,
                        icon: "success"
                    });

                } catch (error) {
                    if (error.response?.status == 401) {
                        logoutUser();
                    } else {
                        const errorMessage = error.response?.data?.ErrorMessage || 'Delete group failed';
                        toast.error(errorMessage);
                    }
                } finally {
                    setLoading(false);
                }
            }
        });
    }

    // Event handler for input change
    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearch(text);

        // Debouncing logic
        if (timerId) {
            clearTimeout(timerId);
        }
        const newTimerId = setTimeout(() => {
            setFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTimerId(newTimerId);
    };

    useEffect(() => {
        // socket.emit("message", 'message content');
        // socket.on("message", (data) => {
        //     console.log('message',data);

        // });
        // console.log('GroupList::socket', socket);
        handleGroupList();
    }, [currentPage, finalSearchText])


    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "paddingTop": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container-fluid mb-4">
                    <div className="row align-items-center page-title">
                        <div className="col col-sm-auto">
                            <div className="input-group input-group-md">
                                <span className="input-group-text text-secondary bg-none" id="titlecalandershow">Manage Groups</span>
                            </div>
                        </div>
                        <div className='col col-sm-auto'>
                            <input type="text" className="form-control" placeholder="Search..." value={search} onChange={handleSearchChange} />

                        </div>
                        <div className="col-auto sm-auto">

                            {/* <div className="btn btn-link btn-square text-secondary"> */}
                            <button onClick={(e) => navigate('/add-group')} className="btn btn-theme btn-md" >Add Group</button>
                            {/* </div> */}

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card border-0 mb-4">
                        {/* <div className="card-header">
                            <div className="row">
                                <div className="col-auto">
                                    <i className="bi bi-shop h5 avatar avatar-40 bg-light-theme rounded"></i>
                                </div>


                                <div className="col-12 col-md-auto">
                                    <div className="input-group ">
                                        <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                    </div>
                                </div>

                            </div>
                        </div> */}
                        <div className="card-body p-0">
                            <table className="table footable" data-show-toggle="true">
                                <thead>
                                    <tr className="text-muted">
                                        <th>Sr No.</th>
                                        <th>Group Name</th>
                                        <th>Contact Count</th>
                                        <th>Description</th>
                                        <th>Created Date</th>

                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {(groupList.length > 0) ? (
                                        groupList.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.totalContact}</td>
                                                <td>{item.description}</td>
                                                <td>{formateDateTime(item.createdAt)}</td>
                                                <td>
                                                    <Icon.Eye size={18} color="blue" className='cursor-pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(`/group/contact-list/${item._id}`)
                                                    }} />
                                                    <Icon.PlusCircle size={18} color="green" className='cursor-pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(`/group/add-contact/${item._id}`)
                                                    }} />
                                                    <Icon.Trash size={18} color="red" className='cursor-pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleGroupDelete(item);
                                                    }} />


                                                </td>
                                            </tr>

                                        ))
                                    ) : (
                                        <tr key="0" >
                                            <th colSpan="20" style={{ textAlign: 'center' }}>No data available..</th>
                                        </tr>
                                    )}



                                </tbody>
                            </table>
                            {totalItems > 0 &&
                                <Pagination
                                    currentPage={currentPage}
                                    totalItems={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            }

                        </div>
                    </div>
                </div>
            </main>
        </div>
        {/* <Footer /> */}
    </>)
}

export default GroupList;