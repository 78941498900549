import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { contactInitialValues, contactSchema, contactExcelInitialValues, contactExcelSchema } from '../../../utils/validationSchema';
import { groupAddContact } from '../../../api/groupApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import { useParams } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import UploadContactSampleFile from '../../../assets/sample-files/UploadContactSampleFile.xlsx'
import Sidebar from '../Sidebar';

function GroupAddContact() {
    const [loading, setLoading] = useState(false);
    const [sidebarHideShow, setSidebarHideShow] = useState(true);

    const { userData } = useAuthContext();
    const navigate = useNavigate();
    const { groupId } = useParams();
    const handleSubmit = async (formData) => {

        // formData = {
        //     "contacts": [
        //         {
        //             "userName": "dfadf",
        //             "mobileNo": "8798455888"
        //         },
        //         {
        //             "userName": "dsfasdf",
        //             "mobileNo": "8888888888"
        //         },
        //         {
        //             "userName": "dfasdf",
        //             "mobileNo": "8775555555"
        //         }
        //     ]
        // }
        var contacts = [];
        formData.contacts.forEach(element => {
            contacts.push({ mobileNo: element.mobileNo, mobileCode: '91', name: element.userName })
        });

        console.log('formData', formData);
        setLoading(true);
        try {
            let postData = {
                groupId: groupId,
                contactNumber: contacts
            }
            let responceData = await groupAddContact(postData);
            toast.success('Contact created successful!');
            navigate('/group')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create contact failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    // Custom input component for file upload
    const FileInput = ({ field, form, ...props }) => {
        const handleChange = (event) => {
            form.setFieldValue(field.name, event.currentTarget.files[0]);
        };

        return <input type="file" onChange={handleChange} {...field} {...props} />;
    };

    const handleExcelSubmit = async (htmlFormData) => {
        setLoading(true);
        try {
            const postData = new FormData();
            postData.append('groupId', groupId);
            postData.append('userId', userData._id);
            postData.append('type', 0);
            postData.append('file', htmlFormData.file);

            let responceData = await groupAddContact(postData);
            toast.success('Contact created successful!');
            navigate('/group')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create contact failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };


    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "paddingTop": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container-fluid mb-4">
                    <div className="row align-items-center page-title">
                        <div className="col col-sm-auto">
                            <div className="input-group input-group-md">
                                <span className="input-group-text text-secondary bg-none" >Add Contact</span>
                            </div>
                        </div>

                        <div className="col-auto sm-auto">

                            {/* <div className="btn btn-link btn-square text-secondary"> */}
                            <button onClick={(e) => navigate('/group')} className="btn btn-theme btn-md" >Back</button>
                            {/* </div> */}

                        </div>
                    </div>
                </div>
                <div className="container">
                    {/* <div className='headering-back-div'>
                        <h5 className="title">Add Contact</h5>
                        <button onClick={(e) => navigate('/group')} className="btn btn-theme btn-md backButton" >Back</button>
                    </div> */}
                    <div className="row justify-content-center mb-2">
                        <div className="col-12 col-lg-8">
                            <h6 className="title">Add Manualy</h6>
                            <div className=" row">
                                <Formik
                                    initialValues={contactInitialValues}
                                    validationSchema={contactSchema}
                                    onSubmit={handleSubmit}>
                                    {({ isSubmitting, values }) => (
                                        <Form>
                                            <FieldArray name="contacts">
                                                {({ insert, remove, push }) => (
                                                    <div className="col-12" style={{ "height": "70vh", "overflow-x": "hidden", "overflow-y": "auto" }}>
                                                        {values.contacts.length > 0 &&
                                                            values.contacts.map((contact, index) => (
                                                                <div className='row' key={index}>
                                                                    <div className="col-5">
                                                                        <div className="form-group mb-3 position-relative check-valid">
                                                                            <div className="input-group input-group-lg">
                                                                                <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                                                <div className="form-floating">
                                                                                    <Field type="text" name={`contacts.${index}.userName`} className="form-control border-start-0" />
                                                                                    <label>Contact Name <span className='star'>*</span></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name={`contacts.${index}.userName`} component="div" className="error" />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group mb-3 position-relative check-valid">
                                                                            <div className="input-group input-group-lg">
                                                                                <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                                                <div className="form-floating">
                                                                                    <Field type="text" name={`contacts.${index}.mobileNo`} className="form-control border-start-0" />
                                                                                    <label>Mobile Number <span className='star'>*</span></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name={`contacts.${index}.mobileNo`} component="div" className="error" />
                                                                    </div>
                                                                    <div className="col-1 d-flex align-items-center">
                                                                        {index == 0 && (
                                                                            <Icon.PlusCircle size={20} color="green" className='cursor-pointer' onClick={() => push({ userName: '', mobileNo: '' })} />
                                                                        )}
                                                                        {index > 0 && (
                                                                            <Icon.DashCircle size={20} color="red" className='cursor-pointer' onClick={() => remove(index)} />

                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Form>
                                    )}
                                    {/* {({ isSubmitting }) => (
                                    <Form>
                                        <div className="col-12">
                                            <div className=' row'>
                                                <div className="col-5">
                                                    <div className="form-group mb-3 position-relative check-valid">
                                                        <div className="input-group input-group-lg">
                                                            <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                            <div className="form-floating">
                                                                <Field type="text" name="userName" className="form-control border-start-0" />
                                                                <label>Contact Name <span className='star'>*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="userName" component="div" className="error" />
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group mb-3 position-relative check-valid">
                                                        <div className="input-group input-group-lg">
                                                            <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                            <div className="form-floating">
                                                                <Field type="text" name="mobileNo" className="form-control border-start-0" />
                                                                <label>Mobile Number </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="mobileNo" component="div" className="error" />
                                                </div>
                                                <div className="col-2">
                                                    <Icon.PlusCircle size={20} color="green" className='cursor-pointer'/>
                                                </div>
                                            </div>
                                            <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                        </div>
                                    </Form>
                                )} */}
                                </Formik>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <h6 className="title">Bulk Upload</h6>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <Formik
                                        initialValues={contactExcelInitialValues}
                                        validationSchema={contactExcelSchema}
                                        onSubmit={handleExcelSubmit}>
                                        {({ isSubmitting, setFieldValue }) => (
                                            <Form >
                                                <div className="mb-2">
                                                    Download Sample excel file.
                                                    <a href={UploadContactSampleFile}>
                                                        <Icon.Download className='cursor-pointer' size={20} color="blue" />
                                                    </a>

                                                    <br />
                                                </div>
                                                <div className="mb-2">
                                                    <div className="form-group mb-3 position-relative check-valid">
                                                        <div className="input-group input-group-lg">
                                                            <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                            <div className="form-floating">

                                                                <input
                                                                    type="file"
                                                                    accept=".xlsx,.xls"
                                                                    onChange={(event) => {
                                                                        setFieldValue('file', event.currentTarget.files[0]);
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="file" component="div" className="error" />
                                                </div>
                                                <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        {/* <Footer /> */}

    </>)
}

export default GroupAddContact;