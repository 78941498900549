import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

import { useAuthContext } from '../../context/AuthContext';
import { getUserDetails } from '../../api/userApi';
import { signupEmbededUser } from '../../api/authApi';


function Header({ toggleSidebar, sidebarHideShow }) {
    const navigate = useNavigate();
    const { logoutUser } = useAuthContext()
    const [profileTab, setProfileTab] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUserInfo = async () => {
        setLoading(true);
        try {
            const responceData = await getUserDetails();
            // console.log('responceData.data', responceData.data)
            setUserInfo(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleProfileTab = (e) => {
        if (profileTab) {
            setProfileTab(false);
        } else {
            setProfileTab(true);
        }
    };
    const handleSignupEmbededUser = async (code) => {
        setLoading(true);
        try {
            let postData = {
                code: code
            }
            const responceData = await signupEmbededUser(postData);
            toast.success('Whatsapp Business API Applied Sucessfully');
            handleUserInfo();
            // setTimeout(() => {
            //     window.location.href = process.env.REACT_APP_FRONTEND_BASE_URL+'dashboard'
            // }, 1000);

            // console.log('handleSignupEmbededUser::responceData.data', responceData.data)

        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Embeded signup failed.';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const initializeEmbededSignup = () => {
        window.fbAsyncInit = function () {
            // JavaScript SDK configuration and setup
            window.FB.init({
                appId: '497078262906011', // Facebook App ID
                cookie: true, // enable cookies
                xfbml: true, // parse social plugins on this page
                version: 'v21.0' //Graph API version
            });
        };

        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        // Session logging message event listener
        window.addEventListener('message', (event) => {

            if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    console.log('message event: ', data); // remove after testing
                    // your code goes here
                }
            } catch {
                console.log('message event: ', event.data); // remove after testing
                // your code goes here
            }
        });
    }
    function launchWhatsAppSignup() {
        // Conversion tracking code
        // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup' });

        // Launch Facebook login
        window.FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
                handleSignupEmbededUser(code)
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: process.env.REACT_APP_EMBEDED_CONFIG_ID, // configuration ID goes here
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
            scope: 'whatsapp_business_management,business_management,catalog_management,public_profile,email',
            extras: {
                setup: {
                }
            }
        });
    }
    useEffect(() => {
        initializeEmbededSignup()
        handleProfileTab()
        handleUserInfo()
    }, []);
    return (<>

        <header className="header">
            <nav className="navbar fixed-top">
                <div className="container-fluid">
                    <button className="btn btn-link btn-square menu-btn me-2" onClick={toggleSidebar}>
                        <Icon.List />
                    </button>
                    <div className="header-title align-self-center">
                        <h5 className="mb-0"></h5>

                    </div>
                    <div className="ms-auto">
                        <div className="row">

                            <div className="col-auto align-self-center" style={{ "padding": "0px" }}>
                                {userInfo && userInfo.statusEnum !== "Approve" && (
                                    <div className='col-12 col-md-12 col-lg-12 embed-div'>
                                        <button onClick={(e) => launchWhatsAppSignup()} className='btn btn-theme btn-md fb'>Apply for whatsapp business API</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ms-auto">
                        <div className="row">

                            <div className="col-auto align-self-center">
                                <div className="dropdown">
                                    <a className={`dd-arrow-none dropdown-toggle ${profileTab ? '' : 'show'}`} onClick={handleProfileTab}>
                                        <div className="row">

                                            <div className="col ps-0 align-self-center d-none d-lg-block">
                                                <p className="mb-0">
                                                    <span className="text-dark username">{userInfo?.businessName}</span><br />
                                                </p>
                                            </div>
                                            <div className="col ps-0 align-self-center d-none d-lg-block">
                                                {/* <i className="bi bi-chevron-down small vm"></i> */}
                                                <Icon.ChevronBarDown />
                                            </div>
                                        </div>
                                    </a>
                                    <div className={`dropdown-menu dropdown-menu-start w-300 ${profileTab ? '' : 'show'}`} style={{ "right": "0" }}>
                                        <div className="dropdown-info bg-radial-gradient-theme">
                                            <div className="row" style={{ "textAlign": "center" }}>
                                                {/* <div className="col-auto">
                                                    <figure className="avatar avatar-50 rounded-circle coverimg vm">
                                                        <img src="assets/img/user-1.jpg" alt="" id="userphotoonboarding3" />
                                                    </figure>
                                                </div> */}
                                                <div className="col align-self-center ps-0">
                                                    <h6 className="mb-0"><span className="username">{userInfo?.mobileNo}</span></h6>
                                                    <p className="text-muted small">{userInfo?.businessName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div><a className="dropdown-item" href="profile.html">My Profile</a></div> */}
                                        {/* <div><a className="dropdown-item" href="profile.html">Change Password</a></div> */}


                                        {/* <div><a className="dropdown-item" href="login.html">Logout</a></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>

    </>)
}

export default Header;