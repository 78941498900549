// src/api/authApi.js
import axiosInstance from './axiosInstance';

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const addTemplate = async (postData) => {
  try {
    const response = await axiosInstance.post('/create-template', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const addBroadcastTemplate = async (postData) => {
  try {
    const response = await axiosInstance.post('/create-whatsapp-template', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTemplate = async (postData,templateId) => {
  try {
    const response = await axiosInstance.put(`/update-template?id=${templateId}`, postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBroadcastTemplate = async (postData,templateId) => {
  try {
    const response = await axiosInstance.put(`/update-whatsapp-template/${templateId}`, postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateList = async (postData) => {
  try {
    const response = await axiosInstance.get('/getall-template',{
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBroadcastTemplateList = async (postData) => {
  try {
    const response = await axiosInstance.get('/get-all-template',{
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const checkTemplateStatus = async (itemId) => {
  try {
    const response = await axiosInstance.get('/check-template-status/'+itemId);
    return response.data;
  } catch (error) {
    throw error;
  }
};



/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const deleteTemplate = async (itemId) => {
  try {
    const response = await axiosInstance.delete(`/delete-template?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBroadcastTemplate = async (itemId) => {
  try {
    const response = await axiosInstance.delete(`/delete-whatsapp-template/${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateInfoApi = async (itemId) => {
  try {
    const response = await axiosInstance.get(`/get-template-by-id?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBroadcastTemplateInfoApi = async (itemId) => {
  try {
    const response = await axiosInstance.get(`/get-template-by-id/${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// count group api

export const getTemplateCount = async () => {
  try {
    const response = await axiosInstance.get('/template-count');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveChatbotApi = async (postData) => {
  try {
    const response = await axiosInstance.post(postData.chatbotId?'/update-chatbot':'/create-chatbot', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};




export const getChatbotApi = async (postData) => {
  try {
    const response = await axiosInstance.get('/get-chatbot-list', {
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChatbotDetailByIdApi = async (postData) => {
  try {
    const response = await axiosInstance.get('/chatbot-detail', {
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//

export const deleteChatbotApi = async (itemId) => {
  try {
    const response = await axiosInstance.delete(`/delete-chatbot?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

