// src/utils/validationSchema.js
import * as Yup from 'yup';

/////////////// Enquiry form ////////////////////////
export const enquiryInitialValues = {
    userName: '',
    mobileNo: '',
    email: '',
};
export const enquirySchema = Yup.object().shape({
    userName: Yup.string().required('User name is required'),
    mobileNo:
        Yup.string().required('Mobile number is required')
            .min(10, 'Mobile number must be 10 digits')
            .max(10, 'Mobile number must be 10 digits'),
    email: Yup.string().email('Invalid email address').required('Email is required')
});

/////////////// Registration form ////////////////////////
export const registrationInitialValues = {
    userName: '',
    mobileNo: '',
    email: '',
    password: '',
};
export const registrationSchema = Yup.object().shape({
    userName: Yup.string().required('User name is required'),
    mobileNo:
        Yup.string().required('Mobile number is required')
            .min(10, 'Mobile number must be 10 digits')
            .max(10, 'Mobile number must be 10 digits'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

/////////////// Login form ////////////////////////
export const loginInitialValues = {
    userName: '',
    mobileNo: '',
    email: '',
    password: '',
};
export const loginSchema = Yup.object().shape({
    mobileNo: Yup.string().required('Mobile number is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

/////////////// Group form ////////////////////////
export const groupInitialValues = {
    groupName: '',
    description: ''
};
export const groupSchema = Yup.object().shape({
    groupName: Yup.string().required('Group name is required'),
    description: Yup.string().required('Description is required'),
});

/////////////// Add group contact form ////////////////////////
export const contactInitialValues = {
    contacts: [{ userName: '', mobileNo: '' }],
};
export const contactSchema = Yup.object().shape({
    contacts: Yup.array().of(
        Yup.object().shape({
            userName: Yup.string().required('Contact Name is required'),
            mobileNo: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Mobile Number is required'),
        })
    ),
});

/////////////// Add group contact form ////////////////////////
export const contactExcelInitialValues = {
    file: '',
};
const Excel_SUPPORTED_FORMATS = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

export const contactExcelSchema = Yup.object().shape({
    file: Yup.mixed()
        .required('Excel file is required')
        .test(
            'fileSize',
            'File too large. it\'s size should be less then 5MB.',
            value => {
                return value && value.size <= (1048576 * 5) // 5MB
            }
        )
        .test(
            'fileType',
            'Unsupported file format. Accepted only excel file',
            value => value && Excel_SUPPORTED_FORMATS.includes(value.type)
        ),
});


/////////////// Add qr send broadcast form ////////////////////////
export const broadcastInitialValues = {
    title: '',
    description: ''
};
export const broadcastSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
});

/////////////// Template form ////////////////////////
export const templateInitialValuesNew = {
    templateName: '',
    category: '',
    language: '',
    header_type: '',
    header_text: '',
    header_image: '',
    header_video: '',
    header_document: '',
    body: '',
    footer: '',
    buttonType: [],
    quickReplies: [{
        "replyButton": ""
    }],
    visitWebsiteButtons: [{
        "buttonText": "",
        "websiteUrl": ""
    }],
    callPhoneNumberButtons: [{
        "buttonText": "",
        "countryCode": "",
        "phoneNumber": ""
    }],
    copyOfferCodeButtons: [{
        "buttonText": "Copy offer code",
        "offerCode": ""
    }],

};

const TEMPALTE_SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const TEMPALTE_IMG_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const TEMPALTE_VDO_FORMATS = ["video/mp4"];
const TEMPALTE_DOC_FORMATS = ["application/pdf"];
// Regex to allow special characters but disallow emojis
const emojiRegex = /[\p{Emoji}]/u;

export const templateSchemaNew = Yup.object().shape({
    // templateName: Yup.string().required('Template name is required'),
    templateName: Yup.string()
        .matches(/^[a-z0-9_]+$/, "Only lowercase letters, numbers, and underscores (_) are allowed")
        .max(512, 'Template name cannot be longer than 512 characters')
        .transform((value) => value.trim()) // Trim value here
        .required("Template name is required"),
    category: Yup.string().required('Category is required'),
    language: Yup.string().required('Language is required'),

    header_type: Yup.string()
        .oneOf(['TEXT', 'IMAGE', 'VIDEO', 'DOCUMENT', ''])
        .nullable(),
    header_text: Yup.string().when('header_type', {
        is: 'TEXT',
        then: () => Yup.string()
            .max(60, 'Header text cannot be longer than 60 characters')
            .transform((value) => value.trim()) // Trim value here
            .required('Header text is required'),
    }),

    header_image: Yup.mixed().when('header_type', {
        is: 'IMAGE',
        then: () => Yup.mixed()
            .required('Header Image is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 5MB.",
                value => !value || (value && value.size <= 5 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be jpg, jpeg, png format.",
                value => value && TEMPALTE_IMG_FORMATS.includes(value.type)
            )
    }),
    header_video: Yup.mixed().when('header_type', {
        is: 'VIDEO',
        then: () => Yup.mixed()
            .required('Header Video is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 16MB.",
                value => !value || (value && value.size <= 16 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be mp4 format.",
                value => value && TEMPALTE_VDO_FORMATS.includes(value.type)
            )
    }),
    header_document: Yup.mixed().when('header_type', {
        is: 'DOCUMENT',
        then: () => Yup.mixed()
            .required('Header Document is required')
            .test(
                "FILE_FORMAT",
                "Selected file should be a PDF.",
                value => value && TEMPALTE_DOC_FORMATS.includes(value.type)
            )
    }),
    body: Yup.string()
        .max(1024, 'Body text cannot be longer than 1024 characters')
        .transform((value) => value.trim()) // Trim value here
        .required('Body is required'),

    quickReplies: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('quickReply')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    replyButton: Yup.string()
                        .transform((value) => value.trim()) // Trim value here
                        .required('This field is required')
                        .max(25, "Button text max limit 25 characters")
                        .test(
                            "no-emojis",
                            "Icons and emojis are not allowed",
                            (value) => !emojiRegex.test(value) // Rejects input if it contains emojis
                        )
                })
            )
    }),
    visitWebsiteButtons: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('visitWebsite')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    buttonText: Yup.string()
                        .transform((value) => value.trim()) // Trim value here
                        .required('Button text is required')
                        .max(25, "Button text max limit 25 characters")
                        .test(
                            "no-emojis",
                            "Icons and emojis are not allowed",
                            (value) => !emojiRegex.test(value) // Rejects input if it contains emojis
                        ),
                    websiteUrl: Yup.string()
                        .url('Must be a valid URL')
                        .required('Website URL is required')
                        .max(2000, "Button text max limit 25 characters")
                })
            )
    }),
    callPhoneNumberButtons: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('callPhoneNumber')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    buttonText:
                        Yup.string()
                            .transform((value) => value.trim()) // Trim value here
                            .required('Button text is required')
                            .max(25, "Button text max limit 25 characters")
                            .test(
                                "no-emojis",
                                "Icons and emojis are not allowed",
                                (value) => !emojiRegex.test(value) // Rejects input if it contains emojis
                            ),
                    countryCode: Yup.string()
                        .transform((value) => value.trim()) // Trim value here
                        .required('Country Code is required')
                        .max(5, "Button text max limit 5 characters"),
                    phoneNumber: Yup.string()
                        .required('Phone Number is required')
                        .max(20, "Number max limit 20 characters")
                })
            )
    }),
    copyOfferCodeButtons: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('copyOfferCode')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    buttonText:
                        Yup.string()
                            .transform((value) => value.trim()) // Trim value here
                            .required('Button text is required')
                            .max(25, "Button text max limit 25 characters")
                            .test(
                                "no-emojis",
                                "Icons and emojis are not allowed",
                                (value) => !emojiRegex.test(value) // Rejects input if it contains emojis
                            ),
                    offerCode: Yup.string()
                        .transform((value) => value.trim()) // Trim value here
                        .required('Offer Code is required')
                        .max(15, "Offer Code max limit 15 characters")
                        .test(
                            "no-emojis",
                            "Icons and emojis are not allowed",
                            (value) => !emojiRegex.test(value) // Rejects input if it contains emojis
                        ),
                })
            )
    })
});

export const templateInitialValues = {
    templateName: '',
    category: '',
    language: '',
    header_type: '',
    header_text: '',
    header_image: '',
    header_video: '',
    header_document: '',
    body: '',
    footer: '',
    button_type: '',
    quickReplies: [''] // Dynamically added input fields

};

export const templateSchema = Yup.object().shape({
    // templateName: Yup.string().required('Template name is required'),
    templateName: Yup.string()
        .matches(/^[a-z0-9_]+$/, "Only lowercase letters, numbers, and underscores (_) are allowed")
        .max(512, 'Template name cannot be longer than 512 characters')
        .required("Template name is required"),
    category: Yup.string().required('Category is required'),
    language: Yup.string().required('Language is required'),

    header_type: Yup.string()
        .oneOf(['TEXT', 'IMAGE', 'VIDEO', 'DOCUMENT', ''])
        .nullable(),
    header_text: Yup.string().when('header_type', {
        is: 'TEXT',
        then: () => Yup.string()
            .max(60, 'Header text cannot be longer than 60 characters')
            .required('Header text is required'),
    }),

    header_image: Yup.mixed().when('header_type', {
        is: 'IMAGE',
        then: () => Yup.mixed()
            .required('Header Image is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 5MB.",
                value => !value || (value && value.size <= 5 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be jpg, jpeg, png format.",
                value => value && TEMPALTE_IMG_FORMATS.includes(value.type)
            )
    }),
    header_video: Yup.mixed().when('header_type', {
        is: 'VIDEO',
        then: () => Yup.mixed()
            .required('Header Video is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 16MB.",
                value => !value || (value && value.size <= 16 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be mp4 format.",
                value => value && TEMPALTE_VDO_FORMATS.includes(value.type)
            )
    }),
    header_document: Yup.mixed().when('header_type', {
        is: 'DOCUMENT',
        then: () => Yup.mixed()
            .required('Header Document is required')
            .test(
                "FILE_FORMAT",
                "Selected file should be a PDF.",
                value => value && TEMPALTE_DOC_FORMATS.includes(value.type)
            )
    }),
    body: Yup.string()
        .max(1024, 'Body text cannot be longer than 1024 characters')
        .required('Body is required'),

    quickReplies: Yup.array().when('button_type', {
        is: 'quick_reply', // If button_type is 'Quick Reply', quickReplies are required
        then: () => Yup.array()
            .of(Yup.string().required('This field is required').max(25, "Button text max limit 25 characters"))
    })

});


/////////////// Template form ////////////////////////
export const templateInitiValIntractive = {

    header_type: '',
    header_text: '',
    header_image: '',
    header_video: '',
    header_document: '',
    body: '',
    footer: '',
    buttonType: [],
    quickReplies: [{
        "replyButton": ""
    }],
    visitWebsiteButtons: [{
        "buttonText": "",
        "websiteUrl": ""
    }],
    callPhoneNumberButtons: [{
        "buttonText": "",
        "countryCode": "",
        "phoneNumber": ""
    }],
    copyOfferCodeButtons: [{
        "buttonText": "Copy offer code",
        "offerCode": ""
    }],

};
export const templateSchemaIntractive = Yup.object().shape({

    header_type: Yup.string()
        .oneOf(['TEXT', 'IMAGE', 'VIDEO', 'DOCUMENT', ''])
        .nullable(),
    header_text: Yup.string().when('header_type', {
        is: 'TEXT',
        then: () => Yup.string()
            .max(60, 'Header text cannot be longer than 60 characters')
            .required('Header text is required'),
    }),

    header_image: Yup.mixed().when('header_type', {
        is: 'IMAGE',
        then: () => Yup.mixed()
            .required('Header Image is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 5MB.",
                value => !value || (value && value.size <= 5 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be jpg, jpeg, png format.",
                value => value && TEMPALTE_IMG_FORMATS.includes(value.type)
            )
    }),
    header_video: Yup.mixed().when('header_type', {
        is: 'VIDEO',
        then: () => Yup.mixed()
            .required('Header Video is required')
            .test(
                "FILE_SIZE",
                "Uploaded file is too big. max file size upto 16MB.",
                value => !value || (value && value.size <= 16 * 1024 * 1024) // 5MB limit
            )
            .test(
                "FILE_FORMAT",
                "Selected file should be mp4 format.",
                value => value && TEMPALTE_VDO_FORMATS.includes(value.type)
            )
    }),
    header_document: Yup.mixed().when('header_type', {
        is: 'DOCUMENT',
        then: () => Yup.mixed()
            .required('Header Document is required')
            .test(
                "FILE_FORMAT",
                "Selected file should be a PDF.",
                value => value && TEMPALTE_DOC_FORMATS.includes(value.type)
            )
    }),
    body: Yup.string()
        .max(1024, 'Body text cannot be longer than 1024 characters')
        .required('Body is required'),

    quickReplies: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('quickReply')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    replyButton: Yup.string().required('This field is required').max(20, "Button text max limit 20 characters")
                })
            )
    }),
    visitWebsiteButtons: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('visitWebsite')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    buttonText: Yup.string().required('Button text is required'),
                    websiteUrl: Yup.string()
                        .url('Must be a valid URL')
                        .required('Website URL is required')
                        .max(2000, "Button text max limit 25 characters")
                })
            )
    }),
    callPhoneNumberButtons: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('callPhoneNumber')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    buttonText: Yup.string().required('Button text is required'),
                    countryCode: Yup.string()
                        .required('Country Code is required')
                        .max(5, "Button text max limit 5 characters"),
                    phoneNumber: Yup.string()
                        .required('Phone Number is required')
                        .max(20, "Number max limit 20 characters")
                })
            )
    }),
    copyOfferCodeButtons: Yup.array().when('buttonType', {
        is: (buttonType) => {
            return buttonType.includes('copyOfferCode')
        },
        then: () => Yup.array()
            .of(
                Yup.object().shape({
                    buttonText: Yup.string().required('Button text is required'),
                    offerCode: Yup.string()
                        .required('Offer Code is required')
                        .max(15, "Offer Code max limit 15 characters"),
                })
            )
    })
});