import React, { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import * as Icon from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';



function TextUpdaterNode({ data,isConnectable }) {
    
    // console.log('data',data);
    

    const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

    const handleCloseMenu = () => {
        setIsOpenContextMenu(false); // Hide the context menu
    };

    const handleOpenMenu = () => {
        setIsOpenContextMenu(true);
    };

    // useEffect(()=>{
    //     console.log('isOpenContextMenu',isOpenContextMenu);

    // },[isOpenContextMenu])


    const onChange = useCallback((evt) => {
        console.log(evt.target.value);
        if (data.onUpdate) {
            data.onUpdate(data.id, evt.target.value);
        }
    }, [data]);

    const onRemove = useCallback(() => {
        if (data.onRemove) {
            console.log('data.id', data.id);

            data.onRemove(data.id);
        }
    }, [data]);

    const handleStyle = { botton: 16, offset:31 };


    return (
        <div className="custom-node">
            {/* create connection bullets */}
            {data.type === 'startNode' ? (
                data.templateInfo?.quickReplies?.length > 0 && 
                data.templateInfo?.quickReplies.map((buttonInfo, index) => {
                    return <Handle 
                    key={index} 
                    id={`${data.id}_handle-${index+1}`} 
                    style={{ top: 'auto', bottom: handleStyle.botton+((data.templateInfo?.quickReplies?.length-index-1)*handleStyle.offset) }}
                    className="node-handle" 
                    type="source" 
                    isConnectable={isConnectable}
                    position={Position.Right} />
                })
             ) 
            : (<>
                <Handle 
                id={`${data.id}_handle-0`} 
                className="conn-node-handle" 
                type="target" position={Position.Left} />

                {
                    data.templateInfo?.quickReplies?.length > 0 && 
                    data.templateInfo?.quickReplies.map((buttonInfo, index) => {
                        return  <Handle 
                        key={index} 
                        id={`${data.id}_handle-${index+1}`} 
                        style={{ top: 'auto', bottom: handleStyle.botton+((data.templateInfo?.quickReplies?.length-index-1)*handleStyle.offset) }}
                        className="node-handle" 
                        type="source" 
                        isConnectable={isConnectable}
                        position={Position.Right} />
                    })
                }
            </>)}

            {/* create cart header */}
            <div className='chatbot_template_header'>
                <div>
                    {data.leftButtonClick === "InteractiveButton" ? (
                        <>
                            <Icon.RecordCircle size={20} />
                            <h6>Buttons</h6>
                        </>
                    ) :
                        data.leftButtonClick === "InteractiveList" ? (
                            <>
                                <Icon.ListCheck size={20} />
                                <h6>List</h6>
                            </>
                        ) :
                        (
                            <>
                                <Icon.RecordCircle size={20} />
                                <h6> Template</h6>
                                
                            </>
                        )}
                </div>
                
                <div className='context-menu-icon'
                    onClick={handleOpenMenu}
                >
                    <Icon.ThreeDotsVertical size={20} />
                </div>
                {/* context menu items */}
                <ul className={`context-menu-item-ul ${isOpenContextMenu ? 'active' : ''}`}
                    onClick={handleCloseMenu}
                >
                    <li style={{ padding: "5px 10px", cursor: "pointer" }}
                        onClick={() => onRemove()}
                    ><Icon.Trash size={10} /> Delete</li>
                </ul>
            </div>
            <div style={{padding:'10px'}} dangerouslySetInnerHTML={{ __html: data.templateInfo.body }} />

            {/* cart button design */}
            {data.templateInfo?.quickReplies?.length > 0 &&
                <div className='node-conn-btns'>
                    {data.templateInfo?.quickReplies.map((buttonInfo, index) => {
                        return (
                            <div key={index} className='conn-btn-item'>
                                {buttonInfo.replyButton}
                            </div>
                        )
                    })}

                </div>
            }

        </div>
    );
}

export default TextUpdaterNode;
