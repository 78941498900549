import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../context/AuthContext';
import { getUserDetails } from '../../api/userApi';
import Header from './Header';
import Loader from '../common/Loader';

function Sidebar({ sidebarHideShow, setSidebarHideShow }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { logoutUser } = useAuthContext();
    const [userInfo, setUserInfo] = useState(null);

    const [loading, setLoading] = useState(false);

    // Handle logout logic
    const handleLogout = (e) => {
        e.preventDefault();
        logoutUser();
    };
    const handleUserInfo = async () => {
        setLoading(true);
        try {
            const responceData = await getUserDetails();
            // console.log('responceData.data', responceData.data)
            setUserInfo(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    // Helper function to determine active state for routes
    const isActiveRoute = (routes) => {

        // return routes.some((route) => location.pathname.startsWith(route));
        return routes.some((route) => location.pathname===route);

    };
    useEffect(() => {
        handleUserInfo()
    }, []);

    return (
        <>
            {userInfo && <>
                {/* Header Component */}
                < Header toggleSidebar={() => setSidebarHideShow((prev) => !prev)} sidebarHideShow={sidebarHideShow} />

                {loading && <Loader />}

                {/* Sidebar Wrapper */}
                <div className="sidebar-wrap" style={{ marginTop: '50px' }}>
                    <div className={`sidebar ${sidebarHideShow ? '' : 'hidden'}`}>
                        <div className="container">
                            <ul className="nav nav-pills">
                                {/* Menu Items */}
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/dashboard" : "#"}

                                        className={`nav-link ${isActiveRoute(['/dashboard']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.Speedometer2 /></div>
                                        <div className="col">Dashboard</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/group" : "#"}
                                        // to="/group"
                                        className={`nav-link ${isActiveRoute(['/group', '/add-group']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.CollectionFill /></div>
                                        <div className="col">Group</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/broadcast-template-list" : "#"}
                                        // to="/broadcast-template-list"
                                        className={`nav-link ${isActiveRoute(['/broadcast-template-list', '/add-broadcast-template', '/edit-broadcast-template']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.FileEarmarkRichtext /></div>
                                        <div className="col">Template</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/chatbot" : "#"}
                                        // to="/broadcast-template-list"
                                        className={`nav-link ${isActiveRoute(['/chatbot', '/add-chatbot', '/edit-chatbot']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.Robot  /></div>
                                        <div className="col">Chatbot</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/chat" : "#"}
                                        // to="/chat"
                                        className={`nav-link ${isActiveRoute(['/chat']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.Chat /></div>
                                        <div className="col">Chat</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/send-broadcast" : "#"}
                                        // to="/send-broadcast"
                                        className={`nav-link ${isActiveRoute(['/send-broadcast']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.Broadcast /></div>
                                        <div className="col">Broadcast</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={userInfo.statusEnum == "Approve" ? "/history-broadcast" : "#"}
                                        // to="/history-broadcast"
                                        className={`nav-link ${isActiveRoute(['/history-broadcast']) ? 'active' : ''}`}
                                    >
                                        <div className="avatar avatar-40 icon"><Icon.BroadcastPin /></div>
                                        <div className="col">History</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" onClick={handleLogout} >
                                        <div className="avatar avatar-40 icon">
                                            <Icon.BoxArrowInDown />
                                        </div>
                                        <div className="col">Log Out</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
}

export default Sidebar;
