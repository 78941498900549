import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Icon from 'react-bootstrap-icons';
// import io from 'socket.io-client';
import { toast } from 'react-toastify';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { broadcastInitialValues, broadcastSchema, } from '../../../utils/validationSchema';
import { sendBroadcastByCloud } from '../../../api/broadcastApi';
import { getGroupList } from '../../../api/groupApi';
import { getUserDetails } from '../../../api/userApi'
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import { getBroadcastTemplateList, getChatbotApi, getBroadcastTemplateInfoApi } from '../../../api/templateApi';
import Pagination from '../../common/Pagination';
import TemplatePreview from '../../common/TemplatePreview';
import Sidebar from '../Sidebar';
// const socket = io(process.env.REACT_APP_API_BASE_URL);

function SendBroadcast() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const navigate = useNavigate();
    const { logoutUser } = useAuthContext();
    const [groupList, setGroupList] = useState([]);
    // const [filePreview, setFilePreview] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [chatbotList, setChatbotList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selectedChatbot, setSelectedChatbot] = useState(null)
    const [sidebarHideShow, setSidebarHideShow] = useState(true);

    const [groupItemsPerPage] = useState(5);
    const [groupCurrentPage, setGroupCurrentPage] = useState(1);
    const [groupTotalItems, setGroupTotalItems] = useState(0)
    const [groupFinalSearchText, setGroupFinalSearchText] = useState('')
    const [groupSearch, setGroupSearch] = useState('')
    const [groupTimerId, setGroupTimerId] = useState(null);

    const [templateItemsPerPage] = useState(5);
    const [templateCurrentPage, setTemplateCurrentPage] = useState(1);
    const [templateTotalItems, setTemplateTotalItems] = useState(0)
    const [templateSearch, setTemplateSearch] = useState('')
    const [templateFinalSearchText, setTemplateFinalSearchText] = useState('')
    const [templateTimerId, setTemplateTimerId] = useState(null);

    const [chatbotItemsPerPage] = useState(5);
    const [chatbotCurrentPage, setChatbotCurrentPage] = useState(1);
    const [chatbotTotalItems, setChatbotTotalItems] = useState(0)
    const [chatbotSearch, setChatbotSearch] = useState('')
    const [chatbotFinalSearchText, setChatbotFinalSearchText] = useState('')
    const [chatbotTimerId, setChatbotTimerId] = useState(null);

    const [broadcastingType, setbroadcastingType] = useState('templateListTab')
    const [templatePreview, setTemplatePreview] = useState({ source: 'SendBroadcast' })




    const handleGroupList = async () => {
        try {
            let postData = {
                limit: groupItemsPerPage,
                skip: (groupCurrentPage - 1) * groupItemsPerPage,
                name: groupFinalSearchText
            }
            const responceData = await getGroupList(postData);
            if (responceData?.result?.data) {
                setGroupTotalItems(responceData.result.count)
                setGroupList(responceData.result.data);
            } else {
                setGroupTotalItems(0)
                setGroupList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Group list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleTemplateList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: templateItemsPerPage,
                skip: (templateCurrentPage - 1) * templateItemsPerPage,
                search: templateFinalSearchText,
                status: 'APPROVED',
                type: 'cloud'
            }
            const responceData = await getBroadcastTemplateList(postData);
            if (responceData?.data) {
                setTemplateTotalItems(responceData.count)
                setTemplateList(responceData.data);
            } else {
                setTemplateTotalItems(0)
                setTemplateList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleChatbotList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: chatbotItemsPerPage,
                skip: (chatbotCurrentPage - 1) * chatbotItemsPerPage,
                search: chatbotFinalSearchText,
                // status: 'APPROVED',
                // type: 'cloud'
            }
            const responceData = await getChatbotApi(postData);
            if (responceData?.data) {
                setChatbotTotalItems(responceData.totalCount)
                console.log('responceData.data', responceData.data);

                setChatbotList(responceData.data);
            } else {
                setChatbotTotalItems(0)
                setChatbotList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Chatbot list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async (formData) => {
        console.log('formData', formData);

        setLoading(true);
        try {
            let postData = {
                name: formData.title,
                description: formData.description,
                groupId: selectedGroup._id,
                // templateId: selectedTemplate._id,
            }

            if(broadcastingType==="templateListTab"){
                if(selectedTemplate._id){
                    postData['templateId']=selectedTemplate._id
                }else{
                    toast.error('Please select template.');
                    return
                }

            }if(broadcastingType==="chatbotListTab"){
                if(selectedChatbot._id){
                    postData['chatbotId']=selectedChatbot._id
                }else{
                    toast.error('Please select chatbot.');
                    return
                }

            }else{
                toast.error('Please select template or chatbot.');
                return
            }
            console.log('postData', postData);
            let responceData = await sendBroadcastByCloud(postData);
            toast.success('Broadcast send successful!');
            navigate('/history-broadcast')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create broadcast failed 01';
            if (errorMessage === "Session not found.") {

                toast.error("Your whatsapp session timeout. Please again scan QR..");
            } else {
                toast.error(errorMessage);
            }
        } finally {
            setLoading(false);
        }
    };


    // Event handler for input change
    const handleGroupSearchChange = (event) => {
        const text = event.target.value;
        setGroupSearch(text);

        // Debouncing logic
        if (groupTimerId) {
            clearTimeout(groupTimerId);
        }
        const newTimerId = setTimeout(() => {
            setGroupFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setGroupTimerId(newTimerId);
    };

    // Event handler for input change
    const handleTemplateSearchChange = (event) => {
        const text = event.target.value;
        setTemplateSearch(text);

        // Debouncing logic
        if (templateTimerId) {
            clearTimeout(templateTimerId);
        }
        const newTimerId = setTimeout(() => {
            setTemplateFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTemplateTimerId(newTimerId);
    };

    // Event handler for input change
    const handleChatbotSearchChange = (event) => {
        const text = event.target.value;
        setChatbotSearch(text);

        // Debouncing logic
        if (templateTimerId) {
            clearTimeout(templateTimerId);
        }
        const newTimerId = setTimeout(() => {
            setChatbotFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setChatbotTimerId(newTimerId);
    };

    useEffect(() => {
        handleGroupList();
    }, [groupCurrentPage, groupFinalSearchText])

    useEffect(() => {
        handleTemplateList();
    }, [templateCurrentPage, templateFinalSearchText])

    useEffect(() => {
        handleChatbotList();
    }, [chatbotCurrentPage, chatbotFinalSearchText])

    useEffect(() => {
        if (!selectedTemplate) return
        console.log('selectedTemplate', selectedTemplate);
        let prevTemplateInfo = selectedTemplate;
        setTemplatePreview(prevState => ({
            ...prevState,
            templateName: prevTemplateInfo.name,
            headerType: prevTemplateInfo.headerType,
            headerText: prevTemplateInfo.headerText,
            headerFile: prevTemplateInfo.headerFile,
            body: prevTemplateInfo.body,
            footer: prevTemplateInfo.footer,
            buttonType: prevTemplateInfo.buttonType ? prevTemplateInfo.buttonType : [],
            quickReplies: prevTemplateInfo.quickReplies ? prevTemplateInfo.quickReplies : [],
            visitWebsiteButtons: prevTemplateInfo.visitWebsiteButtons ? prevTemplateInfo.visitWebsiteButtons : [],
            callPhoneNumberButtons: prevTemplateInfo.callPhoneNumberButtons ? prevTemplateInfo.callPhoneNumberButtons : [],
            copyOfferCodeButtons: prevTemplateInfo.copyOfferCodeButtons ? prevTemplateInfo.copyOfferCodeButtons : [],
        }))
    }, [selectedTemplate])

    const handleSelectedChatbot = async (selectedChatbot) => {
        let levelTemplateInfo = selectedChatbot.levels.find((levelInfo, index) => levelInfo.nodePosition === "startNode")
        try {
            let responceData = await getBroadcastTemplateInfoApi(levelTemplateInfo.templateId)
            
            if (responceData?.data) {
                let prevTemplateInfo = responceData.data;
                setTemplatePreview(prevState => ({
                    ...prevState,
                    templateName: prevTemplateInfo.name,
                    headerType: prevTemplateInfo.headerType,
                    headerText: prevTemplateInfo.headerText,
                    headerFile: prevTemplateInfo.headerFile,
                    body: prevTemplateInfo.body,
                    footer: prevTemplateInfo.footer,
                    buttonType: prevTemplateInfo.buttonType ? prevTemplateInfo.buttonType : [],
                    quickReplies: prevTemplateInfo.quickReplies ? prevTemplateInfo.quickReplies : [],
                    visitWebsiteButtons: prevTemplateInfo.visitWebsiteButtons ? prevTemplateInfo.visitWebsiteButtons : [],
                    callPhoneNumberButtons: prevTemplateInfo.callPhoneNumberButtons ? prevTemplateInfo.callPhoneNumberButtons : [],
                    copyOfferCodeButtons: prevTemplateInfo.copyOfferCodeButtons ? prevTemplateInfo.copyOfferCodeButtons : [],
                }))
            }
        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Get template info failed';
            toast.error(errorMessage);
        }
    }


    useEffect(() => {
        if (!selectedChatbot) return
        handleSelectedChatbot(selectedChatbot);
        
    }, [selectedChatbot])

    useEffect(() => {
        console.log('broadcastingType',broadcastingType);
        
        setSelectedTemplate(null);
        setSelectedChatbot(null)
        
    }, [broadcastingType])



    useEffect(() => {
        console.log('chatbotList', chatbotList);

    }, [chatbotList])
    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "paddingTop": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container-fluid mb-4">
                    <div className="row align-items-center page-title">
                        <div className="col col-sm-auto">
                            <div className="input-group input-group-md">
                                <span className="input-group-text text-secondary bg-none" >Send Broadcast </span>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="container" >
                    <div className="row ">
                        {(selectedTemplate?._id || selectedChatbot?._id) &&
                            <Formik
                                initialValues={broadcastInitialValues}
                                validationSchema={broadcastSchema}
                                onSubmit={handleSubmit}>
                                {({ isSubmitting }) => (
                                    <Form className='Formik-horizontal'>
                                        <div className="col-12 col-md-4 mb-2" style={{ "paddingRight": "20px" }}>

                                            <div className="form-group mb-3 position-relative check-valid">
                                                <div className="input-group input-group-lg">
                                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                    <div className="form-floating">
                                                        <Field type="text" name="title" className="form-control border-start-0" />
                                                        <label>Title <span className='star'>*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage name="title" component="div" className="error" />
                                        </div>
                                        <div className="col-12 col-md-6 mb-2" style={{ "padding": "0px 20px" }}>

                                            <div className="form-group mb-3 position-relative check-valid">
                                                <div className="input-group input-group-lg">
                                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                    <div className="form-floating">
                                                        <Field as="textarea" name="description" className="form-control border-start-0" rows="10" />
                                                        <label>Description</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage name="description" component="div" className="error" />
                                        </div>
                                        <div className="col-12 col-md-2 mb-2">
                                            <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Send</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        }
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="card bg-white border-0 text-center mb-4">
                                <div className="card-body p-4 px-lg-5" style={{ "display": "flex", "justifyContent": "space-around", "padding": "10px" }}>
                                    <div className='qr-div-h'>
                                        <Icon.CollectionFill className='bi display-4 text-theme mb-2 d-block' />
                                    </div>
                                    <div className=''>

                                        <h4>Group</h4>
                                        <p className="text-secondary">Select a group for broadcast.</p>

                                    </div>
                                </div>

                            </div>
                            <div className="card bg-white border-0 text-center mb-4 ">
                                <div className="card-body  ">
                                    <div className="col-12">
                                        <div className="input-group ">
                                            <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                            <input type="text" className="form-control" placeholder="Search..." value={groupSearch} onChange={handleGroupSearchChange} />
                                        </div>
                                    </div>
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <div className="row">


                                                <div className="col-12  border-left-dashed">

                                                    <ul className="list-group list-group-flush bg-none">
                                                        <li className="list-group-item text-secondary">
                                                            {(groupList.length > 0) ? (
                                                                groupList.map((item, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-auto">
                                                                            <input type="radio" name='group' id={`group_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setSelectedGroup(item)
                                                                            }} />&nbsp;
                                                                            <label className='cursor-pointer' htmlFor={`group_id_${item._id}`}> {item.name}</label>
                                                                        </div>
                                                                        <div className="col-auto ps-0">[Contacts - {item.totalContact}]</div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="row">
                                                                    <p>    No data available.. </p>
                                                                </div>

                                                            )}
                                                        </li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {groupTotalItems > 0 &&
                                        <Pagination
                                            currentPage={groupCurrentPage}
                                            totalItems={groupTotalItems}
                                            itemsPerPage={groupItemsPerPage}
                                            setCurrentPage={setGroupCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={selectedTemplate || selectedChatbot ? 'col-12 col-md-4 col-lg-4' : 'col-12 col-md-4 col-lg-4'}>
                            <div className="card bg-white border-0 text-center mb-4">
                                <div className="card-body p-4 px-lg-5" style={{ "display": "flex", "justifyContent": "space-around", "padding": "10px" }}>
                                    <div className='qr-div-h'>
                                        <Icon.FileEarmarkRichtext className='bi display-4 text-theme mb-2 d-block' />
                                    </div>
                                    <div className=''>

                                        <h4>Templete</h4>
                                        <p className="text-secondary">Select a template, chatbot for broadcast.</p>
                                    </div>
                                </div>
                            </div>
                            {(selectedGroup !== null) &&
                                (<div className="card bg-white border-0 text-center mb-4 ">
                                    <div className="card-body  ">
                                        <ul className="nav nav-tabs justify-content-center nav-adminux nav-lg" id="myTab" role="tablist">
                                            <li onClick={() => setbroadcastingType('templateListTab')} className="nav-item" role="presentation">
                                                <button className={`nav-link ${broadcastingType === 'templateListTab' ? 'active' : ''}`} id="templateListTab-tab" data-bs-toggle="tab" data-bs-target="#personal" type="button" role="tab" aria-controls="personal" aria-selected="false" tabIndex="-1">Template</button>
                                            </li>
                                            <li onClick={() => setbroadcastingType('chatbotListTab')} className="nav-item" role="presentation">
                                                <button className={`nav-link ${broadcastingType === 'chatbotListTab' ? 'active' : ''}`} id="chatbotListTab-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">Chatbot</button>
                                            </li>
                                        </ul>

                                        <div style={{ "display": `${broadcastingType === "templateListTab" ? "block" : "none"}` }} className={`tab-pane fade ${broadcastingType === 'templateListTab' ? 'show active' : ''}`} id="templateListTab" role="tabpanel" aria-labelledby="personal-tab">

                                            <div className="col-12 col-md-auto mt-2">
                                                <div className="input-group ">
                                                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                                    <input type="text" className="form-control" placeholder="Search..." value={templateSearch} onChange={handleTemplateSearchChange} />
                                                </div>
                                            </div>
                                            <div className="card border-0 mb-4">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12  border-left-dashed">
                                                            <ul className="list-group list-group-flush bg-none">
                                                                <li className="list-group-item text-secondary">
                                                                    {(templateList.length > 0) ? (
                                                                        templateList.map((item, index) => (
                                                                            <div className="row" key={`temp_`+index}>
                                                                                <div className="col-auto">
                                                                                    <input type="radio" name='template' id={`template_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        setSelectedTemplate(item)
                                                                                        console.log('item', item);
                                                                                    }} />&nbsp;
                                                                                    <label className='cursor-pointer' htmlFor={`template_id_${item._id}`}> {item.name}</label>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="row">
                                                                            <p>    No data available.. </p>
                                                                        </div>

                                                                    )}
                                                                </li>

                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {templateTotalItems > 0 &&
                                                <Pagination
                                                    currentPage={templateCurrentPage}
                                                    totalItems={templateTotalItems}
                                                    itemsPerPage={templateItemsPerPage}
                                                    setCurrentPage={setTemplateCurrentPage}
                                                />
                                            }
                                        </div>
                                        <div style={{ "display": `${broadcastingType === "chatbotListTab" ? "block" : "none"}` }} className={`tab-pane fade ${broadcastingType === 'chatbotListTab' ? 'show active' : ''}`} id="chatbotListTab" role="tabpanel" aria-labelledby="personal-tab">
                                            <div className="col-12 col-md-auto mt-2">
                                                <div className="input-group ">
                                                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                                    <input type="text" className="form-control" placeholder="Search..." value={chatbotSearch} onChange={handleChatbotSearchChange} />
                                                </div>
                                            </div>
                                            <div className="card border-0 mb-4">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12  border-left-dashed">
                                                            <ul className="list-group list-group-flush bg-none">
                                                                <li className="list-group-item text-secondary">
                                                                    {(chatbotList.length > 0) ? (
                                                                        chatbotList.map((item, index) => (

                                                                            <div className="row" key={index}>
                                                                                <div className="col-auto">
                                                                                    <input type="radio" name='chatbot' id={`chatbot_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        setSelectedChatbot(item)
                                                                                        console.log('item', item);
                                                                                    }} />&nbsp;
                                                                                    <label className='cursor-pointer' htmlFor={`chatbot_id_${item._id}`}> {item.name}</label>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="row">
                                                                            <p>    No data available.. </p>
                                                                        </div>

                                                                    )}
                                                                </li>

                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {chatbotTotalItems > 0 &&
                                                <Pagination
                                                    currentPage={chatbotCurrentPage}
                                                    totalItems={chatbotTotalItems}
                                                    itemsPerPage={chatbotItemsPerPage}
                                                    setCurrentPage={setChatbotCurrentPage}
                                                />
                                            }
                                        </div>

                                    </div>
                                </div>)
                            }
                        </div>

                        <div className="col-12 col-md-4 col-lg-4" style={{ height: '100%' }}>
                            {templatePreview  && 
                                <TemplatePreview templatePreview={templatePreview} />
                            }

                        </div>

                    </div>




                </div>




            </main >
        </div >
        {/* <Footer /> */}

    </>)
}

export default SendBroadcast;



// <div className="col-12 col-md-4 col-lg-4">
//     {selectedTemplate &&
//         <div className="row justify-content-center mb-2">
//             <Formik
//                 initialValues={broadcastInitialValues}
//                 validationSchema={broadcastSchema}
//                 onSubmit={handleSubmit}>
//                 {({ isSubmitting }) => (
//                     <Form>
//                         <div className="col-12">
//                             <h6>Broadcast Detail: </h6>
//                             <br />
//                             <div className="mb-2">
//                                 <div className="form-group mb-3 position-relative check-valid">
//                                     <div className="input-group input-group-lg">
//                                         <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
//                                         <div className="form-floating">
//                                             <Field type="text" name="title" className="form-control border-start-0" />
//                                             <label>Title <span className='star'>*</span></label>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <ErrorMessage name="title" component="div" className="error" />
//                             </div>
//                             <div className="mb-2">
//                                 <div className="form-group mb-3 position-relative check-valid">
//                                     <div className="input-group input-group-lg">
//                                         <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
//                                         <div className="form-floating">
//                                             <Field as="textarea" name="description" className="form-control border-start-0" rows="10" />
//                                             <label>Description</label>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <ErrorMessage name="description" component="div" className="error" />
//                             </div>
//                             <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Send</button>
//                         </div>
//                     </Form>
//                 )}
//             </Formik>
//         </div>


//     }
// </div>