import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DragDropExample.css';
import { toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useAuthContext } from '../../../context/AuthContext';
import { signupEmbededUser } from '../../../api/authApi';
import { getTicketList, getGroupTicketList, assignTicketInGroup } from '../../../api/ticketApi';
import Loader from '../../common/Loader';
import { getUserDetails } from '../../../api/userApi';
import Header from '../Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';

// Sample data for tickets and groups
// const initialTickets = [
//     { id: 'ticket1', content: 'Ticket 1' },
//     { id: 'ticket2', content: 'Ticket 2' },
//     { id: 'ticket3', content: 'Ticket 3' },
// ];

const initialGroups = [
    { id: 'group-1', name: 'Group 1', tickets: [] },
    { id: 'group-2', name: 'Group 2', tickets: [] },
];

const DragDropExample = () => {
    const navigate = useNavigate();

    const { logoutUser, userData } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [difaultTicket, setDifaultTicket] = useState([]);
    const [groups, setGroups] = useState([]);
    const [ticketId, setTicketId] = useState();
    const [groupId, setGroupId] = useState();
    const [sidebarHideShow, setSidebarHideShow] = useState(true);

    const handleTicketList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: 10,
                skip: 0,
                search: ''
            }
            const responceData = await getTicketList(postData);

            if (responceData?.data) {
                let data = []
                responceData.data.forEach(item => {
                    console.log('item', item);

                    data.push({
                        id: item._id,
                        content: item.contact.name,
                        time: item.createdAt,
                        unReadCount: item.unReadCount,
                        text: item.messages.message,

                    })
                });
                setTickets(data);
                setDifaultTicket(data);
                // console.log('tickets==>', tickets)
            } else {
                // setTotalItems(0)
                setTickets([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleGroupTicketList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: 10,
                skip: 0,
                search: ''
            }
            const responceData = await getGroupTicketList(postData);

            if (responceData?.data) {
                let data = []
                let updatedDefaultTicket = [];
                responceData.data.forEach(item => {
                    // console.log('item==00', item);
                    let ticketDataAll = []
                    if (item.ticketData.length > 0) {
                        item.ticketData.forEach(ticket => {
                            console.log('ticket0000', ticket);
                            updatedDefaultTicket.push({
                                id: ticket._id,
                                content: ticket.contact.name[0],
                                time: ticket.createdAt,
                                unReadCount: ticket.unReadCount,
                                text: ticket.messages.message[0]
                            });
                            ticketDataAll.push({
                                id: ticket._id,
                                content: ticket.contact.name[0],
                                time: ticket.createdAt,
                                unReadCount: ticket.unReadCount,
                                text: ticket.messages.message[0]
                            })

                        });
                    }
                    // alert('ticket')
                    // setDifaultTicket(difaultTicket)
                    data.push({
                        id: item._id,
                        name: item.name,
                        tickets: ticketDataAll
                    })
                });
                // setDifaultTicket(updatedDefaultTicket);

                setGroups(data);
                console.log('difaultTicket==>', difaultTicket)
            } else {
                console.log('step last')

                // setTotalItems(0)
                setTickets([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async () => {
        if (ticketId != undefined && groupId != undefined) {


            try {
                let setData = {
                    ticketId: ticketId,
                    groupId: groupId
                }
                console.log('setData', setData);

                let responceData = await assignTicketInGroup(setData);


                handleTicketList()
                handleGroupTicketList()
                setTicketId()
                setGroupId()
            } catch (error) {
                const errorMessage = error.response?.data?.ErrorMessage || 'Create template failed';
                toast.error(errorMessage);
            } finally {
                setLoading(false);
            }
        }
    };

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        console.log('destination', destination)
        console.log('source', source)
        console.log('draggableId', draggableId)

        // If dropped outside the valid area, do nothing
        if (!destination) {
            return;
        }

        // If the item is dropped back to the same position, do nothing
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }

        // const movedTicket = difaultTicket.find((t) => t.id === draggableId); // Get the ticket being moved
        // console.log('movedTicket', movedTicket)


        // Moving ticket from tickets section to a group
        if (source.droppableId === 'tickets' && destination.droppableId != undefined && destination.droppableId != null) {
            console.log("newGroupId step 11", destination.droppableId)
            console.log("ticketId step 12", draggableId)
            console.log("oldGroupId step 13", source.droppableId)
            setGroupId(destination.droppableId)
            setTicketId(draggableId)

            // const newGroups = [...groups];
            // const groupIndex = newGroups.findIndex((group) => group.id === destination.droppableId);
            // newGroups[groupIndex].tickets.push(movedTicket.id); // Add ticket to the group

            // setGroups(newGroups); // Update groups state
            // setTickets(tickets.filter((ticket) => ticket.id !== draggableId)); // Remove ticket from the main tickets
            // return;
        } else if (source.droppableId != undefined && source.droppableId != null && destination.droppableId === 'tickets') {
            // Moving ticket from group back to tickets section

            console.log("newGroupId step 21", destination.droppableId)
            console.log("ticketId step 22", draggableId)
            console.log("oldGroupId step 23", source.droppableId)

            setGroupId('empty')
            setTicketId(draggableId)

            // const groupIndex = groups.findIndex((group) => group.id === source.droppableId);
            // const group = groups[groupIndex];

            // const newGroups = [...groups];
            // const ticketIndex = group.tickets.indexOf(draggableId);
            // if (ticketIndex > -1) {
            //     group.tickets.splice(ticketIndex, 1); // Remove ticket from group
            // }

            // // Re-add the ticket back to the tickets section
            // setTickets([...tickets, movedTicket]); // Add ticket back to the tickets section
            // setGroups(newGroups); // Update groups state
            // return;
        } else if (source.droppableId != undefined && source.droppableId != null && destination.droppableId != undefined && destination.droppableId != null) {

            // Moving ticket from one group to another group

            console.log("newGroupId step 01", destination.droppableId)
            console.log("ticketId step 02", draggableId)
            console.log("oldGroupId step 02", source.droppableId)



            // const sourceGroupIndex = groups.findIndex((group) => group.id === source.droppableId);
            // const destinationGroupIndex = groups.findIndex((group) => group.id === destination.droppableId);

            // if (sourceGroupIndex === destinationGroupIndex) {
            //     // If the ticket is being dropped in the same group, no action is needed
            //     return;
            // }

            // // Remove ticket from the source group
            // const newGroups = [...groups];
            // const sourceGroup = newGroups[sourceGroupIndex];
            // const ticketIndex = sourceGroup.tickets.indexOf(draggableId);
            // if (ticketIndex > -1) {
            //     sourceGroup.tickets.splice(ticketIndex, 1); // Remove ticket from source group
            // }
            setGroupId(destination.droppableId)
            setTicketId(draggableId)
            // Add ticket to the destination group
            // const destinationGroup = newGroups[destinationGroupIndex];
            // destinationGroup.tickets.push(draggableId); // Add ticket to the destination group

            // setGroups(newGroups); // Update groups state
            // return;
        }

    };
    const handleLogout = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        logoutUser();
    };
    useEffect(() => {
        handleTicketList();
        handleGroupTicketList();
    }, [])
    useEffect(() => {
        handleSubmit()
    }, [ticketId])

    return (<>

        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "paddingTop": "calc(env(safe-area-inset-top) + 100px)" }}>

                <div className="container ">



                    {/* <div className="row mb-4 py-2">
                        <div className="col text-center">
                            <h4>Don't let poor communication <span className="text-gradient">manipulate progress</span>, while you can track it better</h4>
                        </div>
                    </div> */}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="row ">
                            <Droppable droppableId="tickets">
                                {(provided) => (
                                    <div className="col-12 col-md-3 mb-3" style={{ "box-shadow": "0 0.05rem 0.05rem rgba(0, 0, 0, 0.025), 0 0.095rem 0.3rem rgba(0, 0, 0, 0.065)", "background-color": "#f8fbfe" }}>
                                        <div ref={provided.innerRef} {...provided.droppableProps}
                                            className="row align-items-center mb-3" >
                                            <div className="col-auto">
                                                <div className="avatar avatar-40 bg-yellow text-white rounded">
                                                    <Icon.ListTask />
                                                    {/* <p>{tickets.length}</p> */}
                                                </div>
                                            </div>
                                            <div className="col" >
                                                <h6 className="fw-medium mb-0">New Ticket</h6>
                                                <p className="text-secondary small">Pickup a task</p>

                                            </div>
                                            <div className="col-auto">
                                                <div className="avatar avatar-40 bg-green text-white rounded">
                                                    <p>{tickets.length}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="todocolumn" className="dragzonecard">
                                            {tickets.map((ticket, index) => (
                                                <Draggable key={ticket.id} draggableId={ticket.id} index={index}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps} className="card border-0 mb-4" id="todocolumnone">

                                                            <div className="card border-0 bg-gradient-theme-light theme-green h-100">
                                                                <div className="card-header bg-none">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <h5 className="mb-1">{ticket.content}</h5>
                                                                            {/* <p className="text-muted">{ticket.text}</p> */}
                                                                        </div>
                                                                        <div className="col-auto text-end">
                                                                            {/* <p className="small text-secondary mb-1">Due Date</p> */}
                                                                            {/* <p>30/06/2022</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body bg-none">
                                                                    <div className="progress h-5 mb-2 bg-light-theme">
                                                                        <div className="progress-bar bg-theme" role="progressbar" aria-valuenow="50"
                                                                            aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    {/* <p className="small text-secondary mb-3">Project Status <span className="float-end">30%</span></p> */}
                                                                    <div className="row align-items-center gx-2">

                                                                        <div className="col">
                                                                            <p className="text-secondary small mb-0">{ticket.unReadCount} more</p>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <p>{ticket.time}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <div className="col-12 col-md-9 " >
                                <div className="horizontal-scroll">
                                    {groups.map((group) => (
                                        <Droppable key={group.id} droppableId={group.id}>
                                            {(provided) => (
                                                <div className="col-12 col-md-3 mb-3" ref={provided.innerRef}
                                                    {...provided.droppableProps} style={{ "box-shadow": "0 0.05rem 0.05rem rgba(0, 0, 0, 0.025), 0 0.095rem 0.3rem rgba(0, 0, 0, 0.065)", "backgroundColor": "#f1f9f5", "width": "300px" }}>

                                                    <div className="row align-items-center mb-3">

                                                        <div className="col" style={{ "padding": "10px 10px 10px 25px" }}>
                                                            <h6 className="fw-medium mb-0">{group.name}</h6>
                                                            <p className="text-secondary small">Active Groups</p>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="avatar avatar-40 bg-green text-white rounded">
                                                                <p>{group.tickets.length}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div id="todocolumn" className="dragzonecard">
                                                        {group.tickets.map((ticketId, index) => {
                                                            let ticket = ticketId
                                                            if (!ticket) return null; // Skip if ticket is undefined
                                                            return (
                                                                <Draggable key={ticket.id} draggableId={ticket.id} index={index}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps} className="card border-0 mb-4" id="todocolumntwo">
                                                                            <div className="card border-0 bg-gradient-theme-light theme-blue h-100">
                                                                                <div className="card-header bg-none">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col">
                                                                                            <h5 className="mb-1">{ticket.content}</h5>
                                                                                            <p className="text-muted">{ticket.text}</p>
                                                                                        </div>
                                                                                        <div className="col-auto text-end">
                                                                                            {/* <p className="small text-secondary mb-1">Due Date</p> */}
                                                                                            {/* <p>30/06/2022</p> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-body bg-none">
                                                                                    <div className="progress h-5 mb-2 bg-light-theme">
                                                                                        <div className="progress-bar bg-theme" role="progressbar" aria-valuenow="50"
                                                                                            aria-valuemin="0" aria-valuemax="100"></div>
                                                                                    </div>
                                                                                    {/* <p className="small text-secondary mb-3">Project Status <span className="float-end">30%</span></p> */}
                                                                                    <div className="row align-items-center gx-2">

                                                                                        <div className="col">
                                                                                            <p className="text-secondary small mb-0">1 more</p>
                                                                                        </div>
                                                                                        <div className="col-auto">
                                                                                            <p>10:45 am | 3 hrs</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </div>

                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </DragDropContext>
                </div>
            </main>

        </div>

        {/* <Footer /> */}
    </>);
};

export default DragDropExample;



